import ManualCard from './UseManualCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import UseManualListUtil,{IManualListDataObj} from './useManualListUtil';
import ViewFile from "@/views/sysviews/utils/ViewFile.vue";
export default defineComponent ({
    name: 'ManualList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IManualListDataObj=reactive<IManualListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                showRadio:true,
                queryParam: {},
                modelComp: ManualCard,
                modelMethod: utils.ToolsProviderApi.buildUrl('/useManual/pageData')
            },
            cxData:[]
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new UseManualListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'cxId'==params.comboId){
                    return dataObj.cxData
                }
            }
        })
        const viewFileHandler=async ()=>{
            let id=dataObj.pageListRef.getTbInst().selRowId;
            if(id){
                let name;
                let data=dataObj.pageListRef.getTbData();
                for(let i=0;i<data.length;i++){
                    if(data[i]['F_ID']==id){
                        name=data[i]['F_NAME'];break;
                    }
                }
                if(name){
                    let res=await utils.ToolsProviderApi.queryManualUrl({id:id});
                    if(res.result && res.url){
                        await utils.UtilPub.openDialog({title: name,modelComp:ViewFile,proxy:proxy,ownerComp:dataObj.pageListRef,
                            contentHeight:(window.innerHeight-50)+'px',url:res.url,showFooterBtn:false,fullscreen:true})
                    }else if(res.result){
                        proxy.$message('未上传任何手册');
                    }
                }
            }else{
                proxy.$message({message:'请选择要查看的行'});
            }
        }
        return{
            ...toRefs(dataObj),comboSelect,viewFileHandler
        }
    }
});